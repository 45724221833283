import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { VideoGrp } from 'src/app/model/video-grp.model';
import { Video } from 'src/app/model/video.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageVideoService {

  constructor(private http: HttpClient) {}

  pageVideos = new ReplaySubject<Video[] | undefined>(1);

  getVideoGrps(): Observable<VideoGrp[]> {
    return this.http.get<VideoGrp[]>(environment.apiUrl + 'videos/groups');
  }

  setPageVideos(pageRefCd: string | null): void {
    if (pageRefCd) {
      this.http.get<Video[] | undefined>(environment.apiUrl + 'videos/page/' + pageRefCd).subscribe(pageVideos => {
        this.pageVideos.next(pageVideos);
      });
      return;
    }
    this.pageVideos.next(undefined);
  }

}
